<template>
  <v-container class="general">
    <PageTitle :title="'Roles'" />
    <v-layout class="row wrap">
      <v-flex class="xs12 mb-4">
        <v-card class="pa-4" :style="{ width: '100%' }">
          <v-layout class="row wrap">
            <v-flex class="xs12">
              <v-text-field
                v-model="role.name"
                v-validate.disable="'required'"
                :error-messages="errors.collect('name')"
                :data-vv-name="'name'"
                :data-vv-as="'Name'"
                :label="'Name'"
              ></v-text-field>
            </v-flex>
            <v-flex class="xs12">
              <v-text-field
                v-model="role.description"
                v-validate.disable="'required'"
                :error-messages="errors.collect('description')"
                :data-vv-name="'description'"
                :data-vv-as="'Description'"
                :label="'Description'"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-card> </v-flex
      ><v-flex class="xs12 text-xs-center">
        <v-btn
          @click="$router.push({ name: 'roles' })"
          round
          class="text-none"
          >{{ $t("back") }}</v-btn
        >
        <v-btn
          v-if="!roleId"
          @click="createRole"
          :loading="loading"
          round
          class="primary text-none"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          v-if="roleId"
          @click="updateRole"
          :loading="loading"
          round
          class="primary text-none"
          >{{ $t("save") }}</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    role: {},
  }),
  computed: {
    roleId() {
      return this.$route.params.role_id;
    },
  },
  methods: {
    getRole(id) {
      if (!id) return;

      this.loading = true;

      this.$api.roles.get(id).then(
        (response) => {
          this.loading = false;

          if (!response) return;

          this.role = response.data.data;
        },
        () => {
          this.loading = false;
        },
      );
    },
    async createRole() {
      if (!(await this.$validator.validate())) return;

      const specs = {
        name: this.role.name,
        description: this.role.description,
      };

      this.loading = true;

      this.$api.roles.create(specs).then(
        (response) => {
          this.loading = false;

          if (!response) return;

          this.$router
            .replace({
              name: "roles_edit",
              params: {
                role_id: response.data.data.id,
              },
            })
            .catch(() => {});

          this.$store.dispatch("addNotification", {
            message: `Role \"${response.data.data.description}\" created`,
          });
        },
        (error) => {
          this.loading = false;
          this.errorMessageShow(error);
        },
      );
    },
    async updateRole() {
      if (!(await this.$validator.validate())) return;

      const specs = [
        this.roleId,
        {
          name: this.role.name,
          description: this.role.description,
        },
      ];

      this.loading = true;

      this.$api.roles.update(...specs).then(
        (response) => {
          this.loading = false;

          if (!response) return;

          this.$store.dispatch("addNotification", {
            message: `Role updated`,
          });
        },
        (error) => {
          this.loading = false;
          this.errorMessageShow(error);
        },
      );
    },
  },
  watch: {
    roleId: {
      immediate: true,
      handler(val) {
        this.getRole(val);
      },
    },
  },
};
</script>

<style scoped lang="scss"></style>
